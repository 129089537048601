<template>
  <div class="layout layout_default">
    <atoms-header></atoms-header>
    <main><slot /></main>

    <atoms-footer />
    <modules-interstitial-ad />
    <modules-urgent-banner />
  </div>
</template>

<script lang="ts" setup></script>
<style lang="scss">
.page_main {
  width: 100%;
  min-width: 360px;
  .content_main {
    width: calc(100% - 64px);
    max-width: 1000px;
    margin: 0 auto;
    position: relative;
    text-align: center;
    text-align: -webkit-center;
    .ellipsis_line {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
    .ellipsis_two_line {
      display: -webkit-box;
      box-orient: vertical;
      line-clamp: 2;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
    }
    .break-m {
      display: none;
    }
  }
  .toy_thread_contents {
    img,
    table,
    video,
    audio,
    iframe {
      max-width: 100%;
      object-fit: contain;
      height: auto !important;
    }
    em {
      font-style: italic;
    }
    a {
      color: -webkit-link;
      cursor: pointer;
      text-decoration: underline;
    }
  }
}
@media (max-width: 999px) {
  .page_main {
    .content_main {
      width: auto;
    }
  }
}
@include mobile() {
  .page_main {
    .content_main {
      .break-m {
        display: inline;
      }
    }
  }
}
</style>
