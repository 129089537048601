<template>
  <header>
    <div class="contents_header" :class="{ active: isOpenMobileMenu }">
      <div class="line_btn_mo">
        <atoms-c-image
          v-log-a2s-click
          data-a2s-obj-name="GNB_BI"
          class="img_logo"
          file-name="logo"
          :density="3"
          width="200px"
          @click="moveMenuPath('/')"
        />

        <atoms-c-icon
          class="btn_menu_mo mo_only"
          :icon-name="isOpenMobileMenu ? 'gnb_close' : 'gnb_burger'"
          @click="toggleMobileMenu"
        ></atoms-c-icon>
      </div>
      <div
        v-if="isOpenMobileMenu"
        class="wrap_overview"
        :class="{ signin: hasProfile }"
      >
        <template v-if="hasProfile">
          <p class="txt_nick">
            <span>
              <b>{{ profileName }}</b>
              님
            </span>
            <button class="btn_logout" @click="handleLogout">로그아웃</button>
          </p>
          <span class="txt_code">#{{ profileCode }}</span>
          <div class="wrap_btn">
            <!-- #f6f8fc  -->
            <atoms-base-button
              v-for="(
                { btnClassName, iconName, name, path }, idx
              ) in profileMenu"
              :key="idx"
              :class="btnClassName"
              :text="name"
              @click.stop="moveMenuPath(path)"
            >
              <template #prepend>
                <atoms-c-icon :icon-name="iconName" />
              </template>
            </atoms-base-button>
          </div>
        </template>
        <template v-else>
          <h2 class="txt_title">만나서 반가워요!</h2>
          <p class="txt_desc">
            로그인 / 회원가입을 하고
            <br />
            헬로메이플을 자유롭게 이용해보세요!
          </p>

          <div class="wrap_btn">
            <atoms-default-button
              v-log-a2s-click
              data-a2s-obj-name="GNB_Signup"
              class="btn_signin mo_only"
              :text="loginBtnText"
              @click="router.push({ name: 'login' })"
            ></atoms-default-button>
            <atoms-default-button
              v-log-a2s-click
              data-a2s-obj-name="GNB_Signup"
              class="btn_join mo_only"
              :text="joinBtnText"
              @click="onClickJoin"
            ></atoms-default-button>
          </div>
        </template>
      </div>
      <ul v-show="isOpenMobileMenu" class="section_menu">
        <li
          v-for="(menuItem, idx) in menus"
          :key="idx"
          class="parent_menu"
          :class="{ active: activeMenuId === menuItem.id }"
          @click="onClickMenuItem(menuItem)"
          @mouseenter="setHoverMenu(menuItem.id)"
          @mouseleave="setHoverMenu(null)"
        >
          <div
            v-log-a2s-click
            class="item_menu"
            :data-a2s-obj-name="menuItem.a2sObj"
          >
            <atoms-c-icon :icon-name="menuItem.icon"></atoms-c-icon>
            <span class="text_menu_name">{{ menuItem.name }}</span>
            <atoms-c-icon
              v-if="menuItem.child.length"
              class="mo_only btn_child_menu"
              icon-name="chevron_right"
              :class="{ is_open: openMenuId === menuItem.id }"
            />
          </div>
          <div v-show="openMenuId === menuItem.id" class="wrap_child_menu">
            <ul class="child_menu">
              <li
                v-for="(
                  { name: childName, path, a2sObj }, childIdx
                ) in menuItem.child"
                :key="childIdx"
                v-log-a2s-click
                class="item_child"
                :class="{
                  active: route.path.startsWith(path)
                }"
                :data-a2s-obj-name="a2sObj"
                @click.stop="moveMenuPath(path)"
              >
                <div>{{ childName }}</div>
              </li>
            </ul>
          </div>
        </li>
      </ul>
      <section class="section_sign">
        <template v-if="hasProfile">
          <ul
            class="section_menu"
            @mouseenter="setHoverMenu('profile')"
            @mouseleave="setHoverMenu(null)"
          >
            <li class="parent_menu">
              <span class="txt_nick" :title="profileName ?? ''">
                <b>{{ profileName }}</b>
                <span>님</span>
              </span>
              <div v-show="openMenuId === 'profile'" class="wrap_child_menu">
                <ul class="child_menu">
                  <li class="txt_nick" :title="profileName ?? ''">
                    {{ profileName }}
                  </li>
                  <li class="txt_code">#{{ profileCode }}</li>
                  <li
                    v-for="({ name, path }, idx) in profileMenu"
                    :key="idx"
                    v-log-a2s-click
                    class="item_child"
                    :class="{
                      active: makeSettingActiveClass(path)
                    }"
                    @click.stop="moveMenuPath(path)"
                  >
                    <div>{{ name }}</div>
                  </li>
                  <li class="item_child" @click="handleLogout">
                    <div>로그아웃</div>
                  </li>
                </ul>
              </div>
            </li>
          </ul>
        </template>
        <template v-else>
          <atoms-default-button
            v-log-a2s-click
            data-a2s-obj-name="GNB_Signup"
            class="btn_signin pc_only"
            theme="pink"
            :text="loginBtnText"
            @click="router.push({ name: 'login' })"
          ></atoms-default-button>
          <atoms-default-button
            v-log-a2s-click
            data-a2s-obj-name="GNB_Signup"
            class="btn_join pc_only"
            :text="joinBtnText"
            @click="onClickJoin"
          ></atoms-default-button>
        </template>
      </section>
    </div>
  </header>
</template>

<script lang="ts" setup>
import type { Ref } from 'vue'
import { useRootStore } from '~/stores/root'

interface Menu {
  id: string
  name: string
  icon: string
  path?: string
  a2sObj?: string
  child: {
    name: string
    path: string
    isBoard: boolean
    a2sObj: string
  }[]
}

const menus: Menu[] = [
  {
    id: 'introduce',
    name: '소개',
    icon: 'gnb_intro',
    path: '/introduce',
    a2sObj: 'GNB_Intro',
    child: []
  },
  {
    id: 'speaker',
    name: '소식',
    icon: 'gnb_speaker',
    a2sObj: 'GNB_Updates',
    child: [
      {
        name: '공지사항',
        path: '/notice',
        isBoard: true,
        a2sObj: 'GNB_Notice'
      },
      { name: '뉴스', path: '/news', isBoard: true, a2sObj: 'GNB_News' }
    ]
  },
  {
    id: 'support',
    name: '고객 지원',
    icon: 'gnb_speech_bubble',
    a2sObj: 'GNB_CS',
    child: [
      {
        name: '교육 자료',
        path: '/education-resources',
        isBoard: false,
        a2sObj: '' //'GNB_Download'
      },
      {
        name: '설치 / 실행',
        path: '/download',
        isBoard: false,
        a2sObj: 'GNB_Download'
      },
      {
        name: 'FAQ',
        path: '/faq?headlineId=sticky',
        isBoard: true,
        a2sObj: 'GNB_FAQ'
      }
    ]
  },
  {
    id: 'textbook',
    path: '/textbook',
    name: '교재',
    icon: 'gnb_book',
    child: []
  }
]

const profileMenu = [
  {
    name: '계정 설정',
    path: '/settings',
    btnClassName: 'btn_setting',
    iconName: 'profile_setting'
  },

  {
    name: '1:1 문의',
    path: '/settings/contact',
    btnClassName: 'btn_contact',
    iconName: 'private_question'
  }
]

const router = useRouter()
const route = useRoute()
const authStore = useAuthStore()
const { accessToken } = useAuthToken()
const { isOpenMobileMenu } = storeToRefs(useRootStore())
const { hasProfile, profileCode, profileName } = storeToRefs(useAuthStore())

const routePath = computed(() => route.path)
const activeMenuId = computed(() => {
  for (const idx in menus) {
    const { id, path, child } = menus[idx]
    if (path && routePath.value.startsWith(path)) {
      return id
    }
    const activeChildIdx = child.find((c) => routePath.value.startsWith(c.path))
    if (activeChildIdx) {
      return id
    }
  }
  return null
})
const openMenuId: Ref<string | null> = ref(null)
const loginBtnText = computed(() =>
  accessToken.value ? '계정 설정' : '로그인'
)
const joinBtnText = computed(() =>
  accessToken.value ? '로그아웃' : '회원가입'
)
function setHoverMenu(id: string | null) {
  if (!isOpenMobileMenu.value) {
    openMenuId.value = id
  }
}
function onClickMenuItem(menuItem: Menu) {
  let path = menuItem.path
  if (isOpenMobileMenu.value) {
    if (openMenuId.value === menuItem.id) {
      openMenuId.value = null
    } else {
      openMenuId.value = menuItem.id
    }
  } else if (!path) {
    path = menuItem.child?.[0]?.path
  }

  if (path) {
    moveMenuPath(path)
  }
}
function moveMenuPath(path: string) {
  router.push(path)
  setHoverMenu(null)
  isOpenMobileMenu.value = false
}
function toggleMobileMenu() {
  isOpenMobileMenu.value = !isOpenMobileMenu.value
}

function onClickJoin() {
  if (accessToken.value) {
    handleLogout()
  } else {
    router.push({ name: 'join' })
  }
}

const handleLogout = async () => {
  // 로그아웃 처리
  const success = await authStore.logout()
  if (success) {
    router.push({ name: 'index' })
  }
}

const makeSettingActiveClass = (path: string): string => {
  const currentPath = routePath.value
  if (currentPath.startsWith('/settings')) {
    if (path === '/settings' && !currentPath.startsWith('/settings/contact')) {
      return 'active'
    } else if (
      path === '/settings/contact' &&
      currentPath.startsWith('/settings/contact')
    ) {
      return 'active'
    }
  }
  return ''
}

onMounted(() => {
  // PC 사이즈가 되었을 때 모바일 메뉴가 열려있으면 닫기
  const mql = window.matchMedia('screen and (min-width: 1000px)')
  mql.addEventListener('change', (event) => {
    if (event.matches && isOpenMobileMenu.value) {
      isOpenMobileMenu.value = false
    }
  })
})
onBeforeUnmount(() => {
  isOpenMobileMenu.value = false
})
</script>

<style lang="scss" scoped>
header {
  width: 100%;
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  position: fixed;
  top: 20px;
  margin-top: $bannerHeight;
  z-index: 12;
  .contents_header {
    display: flex;
    max-width: 1280px;
    box-sizing: border-box;
    width: calc(100% - 64px);
    padding: 0 24px;
    margin: 0 auto;
    justify-content: space-between;
    align-items: center;
    border-radius: 500px;
    background: #fff;
    box-shadow: 0 4px 20px 0 rgba(0, 5, 113, 0.06);
    .section_menu {
      display: block !important;
    }
    .img_logo {
      cursor: pointer;
      margin-top: -2px;
    }
    .parent_menu {
      box-sizing: border-box;
      display: inline-block;
      position: relative;
      color: #393c41;
      font-size: 18px;
      font-weight: 700;
      line-height: 1.5;
      letter-spacing: -0.72px;
      padding: 24px 20px;
      cursor: pointer;

      i,
      .text_menu_name {
        vertical-align: middle;
      }
      .text_menu_name {
        padding-left: 8px;
      }
      // 메뉴바와 하위 메뉴 사이의 투명한 영역을 위함
      .wrap_child_menu {
        position: absolute;
        background: transparent;
        top: 78px;
        left: 50%;
        margin-left: -60px;
        padding-top: 10px;
        .child_menu {
          border-radius: 8px;
          background: #ffffff;
          box-shadow: 0 4px 20px 0 rgba(0, 5, 113, 0.06);
          padding: 16px 0;
          color: $black0;
          font-weight: 400;
          cursor: default;
          .item_child {
            min-width: 160px;
            padding: 10px 0;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 16px;
            line-height: 1.4;
            letter-spacing: -0.64px;
            cursor: pointer;
            &:hover,
            &.active {
              background: $bg-l-purple;
              color: $blue1;
              font-weight: 700;
            }
          }
        }
      }
    }
    .section_sign {
      position: relative;
      display: flex;
      width: 196px;
      gap: 8px;
      justify-content: flex-end;

      > .section_menu {
        max-width: 100%;
        height: 80px;
      }
      .parent_menu {
        box-sizing: border-box;
        padding-left: 0;
        width: 100%;
        height: 100%;
        > .txt_nick {
          display: flex;
          color: $blue1;
          font-size: 18px;
          line-height: 150%; /* 27px */
          letter-spacing: -0.72px;
          > b {
            @include textEllipsis;
          }
          > span {
            font-weight: 400;
          }
        }
        .wrap_child_menu {
          max-width: 150%;
          min-width: 160px;
          margin-left: auto;
          transform: translateX(-50%);
        }
        .child_menu {
          font-weight: 400;

          > .txt_nick,
          > .txt_code {
            text-align: center;
            line-height: 140%; /* 19.6px */
          }

          > .txt_nick {
            box-sizing: border-box;
            padding: 0 20px;
            @include textEllipsis;
            font-size: 16px;
            letter-spacing: -0.64px;
            font-weight: 700;
          }
          > .txt_code {
            margin: 4px 0 8px;
            color: $gray0;
            font-size: 14px;

            letter-spacing: -0.56px;
          }
        }
      }
    }

    .btn_signin,
    .btn_join {
      flex-shrink: 0;
      min-width: 94px;
      margin-top: -2px;
      padding: 12px 16px;
      border-radius: 50px;
      letter-spacing: -0.72px;
      :deep(.txt_btn) {
        line-height: 1;
      }
    }
    .btn_signin {
      background-color: $salmon-pink2;
      box-shadow: 0 4px 0 0 #f2764e;

      &:not(:disabled):hover {
        background: #ff9673;
      }
    }
    .btn_join {
      box-shadow: 0 4px 0 0 #3743c4;
    }
  }
}

@media (max-width: 999px) {
  header {
    overflow-y: auto;
    width: 100%;
    top: 0;
    box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.08);
    .contents_header {
      padding: 0;
      border-radius: 0;
      flex-direction: column;
      width: 100%;

      &.active {
        position: fixed;
        padding: 0;
        top: $bannerHeight;
        height: calc(100% - $bannerHeight);
        justify-content: flex-start;
        backface-visibility: hidden;
        -webkit-backface-visibility: hidden;
        .line_btn_mo {
          background: #f3f7fd;
        }
        .section_menu {
          display: flex !important;
        }
      }
      .wrap_overview {
        box-sizing: border-box;
        padding: 24px 24px 42px;
        width: 100%;
        border-radius: 0px 0px 24px 24px;
        background: #f3f7fd;

        &.signin::before {
          height: 266px;
        }
        > * {
          position: relative;
          z-index: 1;
        }
        .txt_title {
          font-size: 26px;
          line-height: 150%; /* 39px */
          letter-spacing: -1.04px;
        }
        .txt_desc {
          margin-top: 4px;
          font-weight: 400;
          line-height: 150%; /* 24px */
          letter-spacing: -0.64px;
        }
        .txt_nick {
          display: flex;
          gap: 16px;
          font-size: 26px;
          line-height: 150%;
          letter-spacing: -1.04px;
        }
        .btn_logout {
          flex-shrink: 0;
          color: $gray0;
          font-size: 15px;
          font-weight: 700;
          line-height: 150%; /* 22.5px */
          letter-spacing: -0.6px;
          text-decoration-line: underline;
          background-color: transparent;
        }
        .txt_code {
          margin-top: 2px;
          color: $gray0;
          font-size: 18px;
          line-height: 140%; /* 25.2px */
          letter-spacing: -0.72px;
        }

        .wrap_btn {
          display: flex;
          margin-top: 16px;
          gap: 8px;
          > button {
            flex: 1;
            width: auto;
            border-radius: 16px;
          }
          .btn_signin,
          .btn_join {
            padding: 14px 24px;
          }

          .btn_setting,
          .btn_contact {
            display: flex;
            padding: 12px 16px;
            justify-content: space-between;
            align-items: center;
            background-color: $white;
            color: $black0;
            font-size: 18px;
            font-weight: 500;
            line-height: 150%; /* 27px */
            letter-spacing: -0.72px;
            transition: all 0.2s ease-in;
            &:not(:disabled):hover {
              background-color: #e7effb;
            }
          }
        }
      }
      .section_menu {
        display: none !important;
        padding: 8px 20px 0;
        width: 100%;
        flex-direction: column;
        overflow-y: auto;
        box-sizing: border-box;

        .parent_menu {
          display: block;
          padding: 0;
          border-bottom: 1px dashed #e9eef4;
          .item_menu {
            display: flex;
            align-items: center;
            padding: 20px 8px;
            font-size: 24px;
            letter-spacing: -0.96px;
          }
          i {
            width: 32px;
            height: 32px;
          }
          .btn_child_menu {
            width: 24px;
            height: 24px;
            background-color: #d6e5fb;
            border-radius: 50%;
            margin-left: auto;
            &.is_open {
              transform: rotate(180deg);
            }
          }
          .wrap_child_menu {
            position: static;
            background: #ffffff;
            margin-left: 0;
            padding-top: 0;

            .child_menu {
              border-radius: 0;
              box-shadow: none;
              padding: 0;

              li {
                box-sizing: border-box;
                width: 100%;
                padding: 16px 24px;
                justify-content: left;
                font-size: 20px;
                font-weight: 500;
                line-height: 1.5;
                letter-spacing: -0.8px;
                background-color: $bg-l-blue1;
              }
            }
          }
        }
      }
      .line_btn_mo {
        box-sizing: border-box;
        display: flex;
        width: 100%;
        padding: 16px;
        z-index: 1;

        .btn_menu_mo {
          cursor: pointer;
          display: block;
          margin-left: auto;
        }
      }
      .img_logo {
        margin-top: 0;
        width: 135px;
        height: 27px;
      }
      .section_sign {
        display: none;
      }
    }
    /*   .btn_join_mo {
      color: $gray0;
      margin-top: auto;
      padding: 50px 0 150px; // ???
      align-self: center;
      font-size: 18px;
      font-weight: 700;
      line-height: 1.5;
      letter-spacing: -0.72px;
      text-decoration-line: underline;
    } */
  }
}
</style>
